<template>
	<div class="h-100">
		<CCard class="h-100">
			<CCardBody class="technician-form">
				<div v-if="!isUpdate" class="shop__header">
					<h5 class="mb-3">
						{{ $t("CustomersTitle.CreateCustomer") }}
					</h5>
				</div>
				<div v-else class="shop__header">
					<h5 class="mb-3">
						{{ getTitle }}
					</h5>
				</div>
				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(submitForm)">
						<div class="custom">
							<div class="custom__input">
								<!-- ID  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="customer_ID"
										name="customer_ID"
										:rules="`max:${CUSTOMER_RULE.ID}|requiredExport`"
										mode="lazy"
									>
										<div class="form-group required row">
											<label for="customer_ID" class="col-md-2 col-12 col-form-label">{{
												$t("CustomerFormFields.ID")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<input
													id="customer_ID"
													v-model.trim="customerForm.id"
													type="text"
													class="form-control"
													:placeholder="$t('CustomerFormFields.ID')"
													autocomplete="customer_ID"
													:maxlength="CUSTOMER_RULE.ID"
													:disabled="isUpdate"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- customer name  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="customer_name"
										:rules="`max:${CUSTOMER_RULE.NAME}|requiredExport`"
									>
										<div class="form-group required row">
											<label for="customer_name" class="col-md-2 col-12 col-form-label">{{
												$t("CustomerFormFields.Customer_name")
											}}</label>
											<div :class="['col-12 col-md-10', classes]">
												<input
													id="customer_name"
													v-model.trim="customerForm.name"
													type="text"
													class="form-control"
													:placeholder="$t('CustomerFormFields.Customer_name')"
													autocomplete="customer_name"
													:maxlength="CUSTOMER_RULE.NAME"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Email  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="email"
										name="customer_email"
										mode="lazy"
										:rules="{
											max: CUSTOMER_RULE.EMAIL,
											isEmail: true,
											requiredExport: !customerForm.emails.length,
										}"
									>
										<!-- :rules="`max:${CUSTOMER_RULE.EMAIL}|isEmail`" -->
										<div class="form-group required row">
											<label for="customer_email" class="col-md-2 col-12 col-form-label">{{
												$t("CustomerFormFields.Email")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<div class="d-flex">
													<input
														id="customer_email"
														v-model.trim="email"
														type="text"
														class="form-control custom__form"
														:placeholder="$t('CustomerFormFields.Email')"
														autocomplete="customer_email"
														:maxlength="CUSTOMER_RULE.EMAIL"
													/>
													<button
														class="px-4 ml-2 btn btn-outline-custom"
														type="button"
														@click="addEmail"
													>
														{{ $t("CustomerButtons.add") }}
													</button>
												</div>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
												<template v-if="customerForm.emails && customerForm.emails.length">
													<div class="email__list">
														<div
															v-for="(item, index) in customerForm.emails"
															:key="index"
															class="email__list__item"
														>
															<div class="email__list__item__name">{{ item }}</div>
															<button
																type="button"
																class="btn btn-outline-danger"
																@click="deleteEmail(index)"
															>
																{{ $t("Button.Delete") }}
															</button>
														</div>
													</div>
												</template>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Rule  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="customer_rule"
										:rules="`requiredExport|max:${CUSTOMER_RULE.RULE}`"
										mode="lazy"
									>
										<div class="form-group required row">
											<label for="customer_rule" class="col-md-2 col-12 col-form-label">{{
												$t("CustomerFormFields.Rule")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<textarea
													id="customer_rule"
													v-model.trim="customerForm.rule"
													type="text"
													rows="6"
													class="form-control remove-resize"
													:placeholder="$t('CustomerFormFields.Rule')"
													autocomplete="customer_rule"
													:maxlength="CUSTOMER_RULE.RULE"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- price table -->
								<div class="form-group position-relative">
									<div class="form-group required row">
										<label for="customer_rule" class="col-md-2 col-12 col-form-label">{{
											$t("CustomerFormFields.PriceTable")
										}}</label>
										<div :class="['col-md-10 col-12']">
											<CustomTable
												:show-filter-search="false"
												:show-filter-received-time="false"
												:show-search-button="false"
												:show-total-items="false"
												:recent-filter="recentFilters"
												:total-pages="totalPages"
												:total-items="totalItems"
												:show-item-per-page="false"
												:no-query="true"
												class="table-price"
											>
												<template #headers>
													<tr>
														<th
															v-for="(header, index1) in headers"
															:key="`${header.title}-${index1}`"
															scope="col"
															:class="`${header.class}`"
														>
															{{ header.title }}
														</th>
													</tr>
												</template>
												<template #body>
													<template v-if="typesOrder && typesOrder.length">
														<tr v-for="(item, index2) in typesOrder" :key="`${item.id}-${index2}`">
															<td scope="row" class="text-break text-center fit">
																{{ item.id || item.typeId }}
															</td>
															<td scope="row" class="text-break text-center">
																<ValidationProvider
																	v-slot="{ classes, errors }"
																	:name="`type_order_${index2}`"
																	:rules="`requiredExport|isNumber|max:${CUSTOMER_RULE.AMOUNT}`"
																>
																	<div :class="[classes]">
																		<input
																			id="amount"
																			v-model="customerForm.orderType[item.id || item.typeId]"
																			type="text"
																			class="form-control amount"
																			autocomplete="amount"
																			:maxlength="CUSTOMER_RULE.AMOUNT"
																			@paste="handleOnPaste($event)"
																			@keydown="checkValidPhone($event)"
																		/>
																	</div>
																	<div v-if="errors[0]" class="error-mess text-red">
																		{{ errors[0] }}
																	</div>
																</ValidationProvider>
															</td>
														</tr>
													</template>
													<template v-else>
														<tr class="text-center">
															<td colspan="11" class="fit">
																{{ $t("Table.NoData") }}
															</td>
														</tr>
													</template>
												</template>
											</CustomTable>
										</div>
									</div>
								</div>
							</div>
						</div>

						<CRow class="text-right">
							<CCol>
								<button type="button" class="btn btn-outline-danger" @click="cancel">
									{{ $t("Button.Cancel") }}
								</button>
								<button class="px-4 ml-2 btn btn-outline-custom" type="submit">
									{{ isUpdate ? $t("Button.Update") : $t("Button.Create") }}
								</button>
							</CCol>
						</CRow>
					</form>
				</ValidationObserver>
			</CCardBody>
		</CCard>
		<CustomModal
			v-if="showMessageEmail"
			:submit-text="$t('Button.OK')"
			:is-show-cancel="false"
			@submit-modal="closePopup"
			@cancel-modal="closePopup"
		>
			<template #content>
				{{ $t("CustomerMessage.Email") }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { CUSTOMER_RULE } from "@/shared/plugins/constants"
import moment from "moment"
import { SEARCH_EMAIL } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState, mapMutations } = createNamespacedHelpers("customers")
import { startCase } from "lodash-es"
import { SET_VARIABLE } from "../store/mutation-types"
const { mapState: typeOrderState } = createNamespacedHelpers("typeOrder")
export default {
	name: "CustomerForm",
	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			CUSTOMER_RULE,
			startCase,
			formatDate: process.env.VUE_APP_DATE_FORMAT,
			createdDate: moment(new Date()).format("DD/MM/YYYY"),
			email: null,
			showMessageEmail: false,
			customerForm: {
				name: null,
				rule: null,
				emails: [],
				id: null,
				orderType: {},
			},
			headers: [
				{
					title: this.$t("CustomerFieldTable.Type"),
					class: "type text-center",
				},
				{
					title: this.$t("CustomerFieldTable.Price"),
					class: "text-nowrap title text-center",
				},
			],
		}
	},
	computed: {
		...mapState(["isErr", "customerDetail", "messageErr"]),
		...typeOrderState(["typesOrder", "recentFilters", "totalPages", "totalItems"]),
		getTitle() {
			return (
				`${startCase(this.customerDetail?.name)} ${this.$t("CustomersTitle.UpdateCustomer")}` || ""
			)
		},
	},
	watch: {
		customerDetail() {
			this.setUpData()
		},
		messageErr(val) {
			if (val) this.setFormFieldErr("customer_ID", [val])
		},
		typesOrder: {
			handler(val) {
				if (val.length > 0) {
					if (!this.isUpdate) {
						val.forEach(el => {
							this.customerForm.orderType[el.id] = 0
						})
					} else {
						this.customerForm.orderType = this.getPrice()
					}
				}
			},
			deep: true,
		},
	},
	created() {
		this.setUpData()
	},
	methods: {
		...mapActions({ SEARCH_EMAIL }),
		...mapMutations({ SET_VARIABLE }),
		submitForm: async function () {
			this.SET_VARIABLE(["messageErr", null])
			if (!this.customerForm.emails.length) {
				return this.showPopup()
			}
			let params = {
				emails: this.customerForm.emails,
				name: this.customerForm.name,
				rule: this.customerForm.rule,
				customerId: this.customerForm.id,
				orderType: this.setPrice(),
			}
			if (this.isUpdate) {
				delete params.customerId
				params["id"] = this.customerForm.id
			}
			this.$emit("submit", params)
		},
		cancel: function () {
			this.$emit("cancel")
		},
		setUpData() {
			if (this.isUpdate && this.customerDetail) {
				this.customerForm = { ...this.customerDetail }
				this.customerForm.orderType = this.getPrice()
			}
		},

		getPrice() {
			let obj = {}
			this.typesOrder.forEach(el => {
				obj[el.typeId] = el.cost
			})
			return obj
		},

		setPrice() {
			const orderTypes = []
			for (const property in this.customerForm.orderType) {
				const obj = {}
				obj["typeId"] = property
				obj["cost"] = parseInt(this.customerForm.orderType[property])
				orderTypes.push(obj)
			}
			return orderTypes
		},

		async addEmail() {
			const { valid } = await this.$refs.email.validate()
			if (!valid) return
			if (!this.email) {
				return this.setFormFieldErr("email", [this.$t("CustomerMessage.Required")])
			}
			const params = {
				data: {
					search: this.email,
				},
				notLoading: false,
			}
			await this.SEARCH_EMAIL(params)
			if (this.isErr) {
				return this.setFormFieldErr("email", [this.$t("CustomerMessage.Email_already")])
			}
			const emailFilter = this.customerForm.emails.filter(el => el === this.email)
			if (emailFilter.length) {
				return this.setFormFieldErr("email", [this.$t("CustomerMessage.Email_already")])
			}
			this.customerForm.emails.push(this.email)
			this.email = null
			this.$refs.email.reset()
		},
		deleteEmail(index) {
			if (this.customerForm.emails.length === 1) {
				return this.showPopup()
			}
			return this.customerForm.emails.splice(index, 1)
		},
		showPopup() {
			this.showMessageEmail = true
			this.$store.commit("set", ["modalShow", true])
		},
		closePopup() {
			this.showMessageEmail = false
			this.$store.commit("set", ["modalShow", false])
		},
		setFormFieldErr(field, err) {
			if (this.$refs[field]) this.$refs[field].setErrors(err)
		},
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.custom__input {
	width: 100%;
}
.custom__form {
	width: calc(100% - 78px);
}
.email__list {
	background: $grey-5;

	&__item {
		padding: 12px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 0.25rem;
	}
}

.amount {
	max-width: 200px;
	margin: auto;
	text-align: center;
}

.text-red {
	color: $red;
}
</style>

<style lang="scss">
@import "@/assets/scss/variables.scss";
.table-price {
	.form-group {
		margin: 0;
	}

	.card-body {
		padding: 0;
	}
}
</style>
